import * as ROLES from '../constants/roles';
import * as ROUTES from '../constants/routes';

export const signIn = (credentials) => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();

    firebase
      .auth()
      .signInWithEmailAndPassword(credentials.email, credentials.password)
      .then(() => {
        dispatch({ type: 'LOGIN_SUCCESS' });
      })
      .catch((err) => {
        dispatch({ type: 'LOGIN_ERROR', err });
      });
  };
};

export const signOut = () => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();

    firebase
      .auth()
      .signOut()
      .then(() => {
        window.location.href = ROUTES.LOGIN;
        dispatch({ type: 'SIGNOUT_SUCCESS' });
      });
  };
};

export const createNewUser = (newUser) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();
    //console.log('this is my password', newUser.password);
    firebase
      .auth()
      .createUserWithEmailAndPassword(newUser.email, newUser.password)
      .then((resp) => {
        const userID = resp.user.uid;

        return firestore
          .collection('users')
          .doc(resp.user.uid)
          .set({
            firstName: newUser.firstname,
            lastName: newUser.lastname,
            fullName: newUser.firstname + ' ' + newUser.lastname,
            initials: newUser.firstname[0] + newUser.lastname[0],
            company: [newUser.company],
            email: newUser.email,
            role: newUser.role,
            userAdded: new Date(),
          })
          .then((resp) => {
            //Update the company with the new users
            //console.log('this is my resp', resp);
            if (newUser.role === ROLES.COMPANY) {
              return firestore
                .collection('companies')
                .doc(newUser.company)
                .update({
                  owner: firestore.FieldValue.arrayUnion(userID),
                })
                .then(() => {
                  dispatch({ type: 'NEW_USER_CREATED' });
                });
            } else if (newUser.role === ROLES.STAFF) {
              return firestore
                .collection('companies')
                .doc(newUser.company)
                .update({
                  staff: firestore.FieldValue.arrayUnion(userID),
                })
                .then(() => {
                  dispatch({ type: 'NEW_USER_CREATED' });
                });
            } else {
              dispatch({ type: 'NEW_USER_CREATED' });
            }
          })
          .catch((err) => {
            console.log('this is my error', err.message);
          });
      })
      .catch((err) => {
        dispatch({ type: 'NEW_USER_ERROR', err });
      });
  };
};

export const deleteUser = (user) => {
  console.log('authActions deleteUser');
  console.log(user);

  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();
    firestore
      .collection('users')
      .doc(user.id)
      .delete()
      .then((user) => {
        console.log(`Successfully deleted user`);
        dispatch({ type: 'USER_WAS_DELETED' });
      })
      .catch((error) => {
        console.log('Error deleting user:', error);
      });
  };
};

export const archiveUser = (user) => {
  console.log('authActions archiveUser');
  console.log(user);

  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();
    firestore
      .collection('users')
      .doc(user.id)
      .update({
        company: [],
        disabled: true,
      })
      .then((user) => {
        console.log(`Successfully archived user`);
        dispatch({ type: 'USER_WAS_ARCHIVED' });
      })
      .catch((error) => {
        console.log('Error archiving user:', error);
      });
  };
};

export const enableUser = (user) => {
  console.log('authActions enableUser');
  console.log(user);

  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();
    firestore
      .collection('users')
      .doc(user.id)
      .update({
        disabled: false,
      })
      .then((user) => {
        console.log(`Successfully enabled user`);
        dispatch({ type: 'USER_WAS_ENABLED' });
      })
      .catch((error) => {
        console.log('Error enabling user:', error);
      });
  };
};

export const updateUserInfoDB = (user) => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();
    //console.log('this is my user info now', user);

    let companies = [];

    /* for (let i = 0; i < user.companyCount; i++) {
      companies.push(user['company' + i + 'ID']);
    }
     */ //console.log('this is my new company list', companies);
    firestore
      .collection('users')
      .doc(user.id)
      .update({
        firstName: user.firstName,
        lastName: user.lastName,
        fullName: user.firstName + ' ' + user.lastName,
        initials: user.firstName[0] + user.lastName[0],
        phone: user.phone,
        title: user.title,
        company: companies,
        country: user.country,
        role: user.role,
      })
      .then(() => {
        dispatch({ type: 'USER_DB_INFO_UPDATED' });
      })
      .catch((err) => {
        dispatch({ type: 'USER_DB_INFO_ERROR', err });
      });
  };
};

export const updateUserEmailInfo = (user) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();

    const currentUser = firebase.auth().currentUser;
    const credential = firebase.auth.EmailAuthProvider.credential(user.originalEmail, user.currentPassword);
    currentUser
      .reauthenticateWithCredential(credential)
      .then(() => {
        currentUser
          .updateEmail(user.email)
          .then(() => {
            firestore
              .collection('users')
              .doc(user.userId)
              .update({
                email: user.email,
              })
              .then(() => {
                dispatch({ type: 'USER_EMAIL_HAS_BEEN_UPDATED' });
              })
              .catch((err) => {
                dispatch({ type: 'USER_EMAIL_NOT_UPDATED', err });
              });
          })
          .catch((err) => {
            dispatch({ type: 'USER_ID_EMAIL_NOT_UPDATED', err });
          });
      })
      .catch((err) => {
        dispatch({ type: 'CHANGE_EMAIL_REAUTHENTICATION_ERROR', err });
      });
  };
};

export const updateUserPassword = (user) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();

    const currentUser = firebase.auth().currentUser;
    const credential = firebase.auth.EmailAuthProvider.credential(user.email, user.currentPassword);
    currentUser
      .reauthenticateWithCredential(credential)
      .then(() => {
        currentUser
          .updatePassword(user.newPassword)
          .then(() => {
            dispatch({ type: 'USER_PASSWORD_WAS_UPDATED' });
          })
          .catch((err) => {
            dispatch({ type: 'USER_PASSWORD_WAS_NOT_UPDATED', err });
          });
      })
      .catch((err) => {
        dispatch({ type: 'CHANGE_PASSWORD_REAUTHENTICATION_ERROR', err });
      });
  };
};

export const sendEmailPasswordReset = (user) => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();
    console.log('this is my user email', user.email);
    console.log('this is my email', user.email);
    firebase
      .auth()
      .sendPasswordResetEmail(user.email)
      .then(() => {
        dispatch({ type: 'USER_EMAIL_RESET_SENT' });
      })
      .catch((err) => {
        dispatch({ type: 'USER_EMAIL_RESET_NOT_SENT', err });
      });
  };
};

export const closeAuthPopUp = () => {
  return (dispatch) => {
    dispatch({ type: 'CLOSE_AUTH_POPUP' });
  };
};

export const addUserFunction = (user) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();

    const sendUser = firebase.functions().httpsCallable('addUserFromAdmin');
    dispatch({ type: 'STARTING_USER_CREATION' });

    sendUser(user).then((result) => {
      //console.log('this is my result back', result.data);
      if (result.data.success === true) {
        //console.log('this is my result back', result.data);
        dispatch({ type: 'USER_WAS_CREATED' });
      } else if (result.data.success === false) {
        dispatch({ type: 'FAILED_TO_CREATE_USER', result });
      }
    });
  };
};

export const createUserListFunction = (user) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();

    const sendUser = firebase.functions().httpsCallable('createUserList');
    dispatch({ type: 'STARTING_USER_CREATION' });

    sendUser(user).then((result) => {
      //console.log('this is my result back', result.data);
      if (result.data.success === true) {
        //console.log('this is my result back', result.data);
        dispatch({ type: 'USER_WAS_CREATED' });
      } else if (result.data.success === false) {
        dispatch({ type: 'FAILED_TO_CREATE_USER', result });
      }
    });
  };
};

export const sortUserCompanies = (user) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();

    const sendUser = firebase.functions().httpsCallable('userCompanyToArray');
    dispatch({ type: 'STARTING_USER_CREATION' });

    sendUser(user).then((result) => {
      //console.log('this is my result back', result.data);
      if (result.data.success === true) {
        //console.log('this is my result back', result.data);
        dispatch({ type: 'USER_WAS_CREATED' });
      } else if (result.data.success === false) {
        dispatch({ type: 'FAILED_TO_CREATE_USER', result });
      }
    });
  };
};

export const editingThisUser = (userID) => {
  return (dispatch) => {
    dispatch({ type: 'LOADING_INFO_FOR_THIS_USER', userID });
  };
};

export const sortUserList = () => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();
    //const firebase = getFirebase();
    //console.log('this is my end date', moment(newPromo.promoEndDate).valueOf())

    firestore
      .collection('users')
      .orderBy('lastName')
      .get()
      .then((snapShot) => {
        if (!snapShot.isEmpty) {
          let userList = [];
          snapShot.forEach((user) => {
            //console.log('this is my company', company.data());
            userList.push({ id: user.id, user: user.data().fullName });
          });

          console.log('this is my userList', userList);
          firestore
            .collection('lists')
            .doc('userList')
            .update({
              users: userList,
            })
            .then(() => {
              firestore.collection('counts').doc('userCount').update({
                count: userList.length,
              });
            });
        } else {
          dispatch({ type: 'NO_USERS_FOUND' });
        }
      })
      .catch((err) => {
        dispatch({ type: 'ERROR_GETTING_USERS', err });
      });
  };
};

import React, { Suspense, lazy } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import ErrorBoundary from '../Errors';
import SystemAlerts from '../Alerts';
import * as ROUTES from '../../constants/routes';

const LoginPage = lazy(() => import('../SignIn'));
const LogoutPage = lazy(() => import('../Signout'));
const UserAdminDashboard = lazy(() => import('../Users/adminUserPannel'));
const TechHomePage = lazy(() => import('../Technologies'));
const LoadingBlock = lazy(() => import('../Loading'));
const TierHomePage = lazy(() => import('../Tiers'));
const ForgotPasswordForm = lazy(() => import('../SignIn/forgotPassword'));
const UserDashboard = lazy(() => import('../Users'));

const ROI = lazy(() => import('../Home'));

const App = () => (
  <Router>
    <div>
      <Suspense fallback={<LoadingBlock />}>
        <Switch>
          <ErrorBoundary>
            <Route exact path={ROUTES.LANDING} component={ROI} />
            <Route exact path={ROUTES.LOGIN} component={LoginPage} />
            <Route exact path={ROUTES.LOGOUT} component={LogoutPage} />
            <Route exact path={ROUTES.USER} component={UserAdminDashboard} />
            <Route exact path={ROUTES.USERS} component={UserDashboard} />
            <Route exact path={ROUTES.TECH} component={TechHomePage} />
            <Route exact path={ROUTES.TIERS} component={TierHomePage} />
            <Route exact path={ROUTES.FORGOTTEN_PASSWORD} component={ForgotPasswordForm} />
          </ErrorBoundary>
        </Switch>
      </Suspense>
      <SystemAlerts />
    </div>
  </Router>
);

export default App;

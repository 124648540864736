import authReducer from './authReducer';
import companyReducer from './companyReducer';

import brandReducer from './brandReducer';

import technologyReducer from './technologyReducer';
import tierReducer from './tierReducer';
import itemReducer from './itemReducer';

import { combineReducers } from 'redux';
import { firestoreReducer } from 'redux-firestore';
import { firebaseReducer } from 'react-redux-firebase';

const rootReducer = combineReducers({
  firestore: firestoreReducer,
  firebase: firebaseReducer,
  auth: authReducer, //Handles all of the login infromation and user actions
  company: companyReducer, //Handles all company information
  brand: brandReducer, //Handles the different Solta Brands
  technology: technologyReducer, //Handles the different Technology
  items: itemReducer, //Handles all of the technology
  tiers: tierReducer, //Handles the different tiers for pricing of items
});

export default rootReducer;

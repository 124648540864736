import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import * as LANGUAGES from './constants/languages';

import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

const fallbackLng = [LANGUAGES.ENGLISH];
const availableLanguages = [LANGUAGES.ENGLISH, LANGUAGES.FRENCH, LANGUAGES.ITALIAN, LANGUAGES.GERMAN, LANGUAGES.SPANISH];

i18n
  // load translation using xhr -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
  // learn more: https://github.com/i18next/i18next-xhr-backend
  .use(Backend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)

  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: fallbackLng,
    whitelist: availableLanguages,
    debug: false,
    saveMissing: false, // send not translated keys to endpoint

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },

    react: {
      // ...
      hashTransKey: function (defaultValue) {
        // return a key based on defaultValue or if you prefer to just remind you should set a key return false and throw an error
      },
      //defaultTransParent: 'div', // a valid react element - required before react 16
      transEmptyNodeValue: '', // what to return for empty Trans
      transSupportBasicHtmlNodes: true, // allow <br/> and simple html elements in translations
      transKeepBasicHtmlNodesFor: ['br', 'sup', 'strong', 'i'], // don't convert to <1></1> if simple react elements
      transWrapTextNodes: '',
    },
  });

export default i18n;

import { compose } from 'redux';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import { closeAuthPopUp } from '../../actions/authActions';

import { closeCompanyPopups } from '../../actions/companyActions';
import styles from '../Styles';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
  return <MuiAlert elevation={10} variant='filled' {...props} />;
}

class SystemAlerts extends Component {
  closePopUps = () => {
    this.props.closeAuthPopUp();
    this.props.closeCompanyPopups();
  };

  render() {
    const { authError, authMessage, authMessagePopup, authErrorPopup, tierError, tierErrorPopup, tierMessage, tierPopUp, itemError, itemMessage, itemPopUp, itemErrorPopup, companyError, companyMessage, companyErrorPopup, companyMesPopup } = this.props;

    return (
      <div className='alerts'>
        <Snackbar open={authMessagePopup} autoHideDuration={6000} onClose={this.closePopUps}>
          <Alert onClose={this.closePopUps} severity='success'>
            Auth Message - {authMessage}
          </Alert>
        </Snackbar>
        <Snackbar open={authErrorPopup} autoHideDuration={6000} onClose={this.closePopUps}>
          <Alert onClose={this.closePopUps} severity='error'>
            Auth Error - {authError}
          </Alert>
        </Snackbar>
        <Snackbar open={tierPopUp} autoHideDuration={6000} onClose={this.closePopUps}>
          <Alert onClose={this.closePopUps} severity='success'>
            Tier Message - {tierMessage}
          </Alert>
        </Snackbar>
        <Snackbar open={tierErrorPopup} autoHideDuration={6000} onClose={this.closePopUps}>
          <Alert onClose={this.closePopUps} severity='error'>
            Tier Error - {tierError}
          </Alert>
        </Snackbar>

        <Snackbar open={itemPopUp} autoHideDuration={6000} onClose={this.closePopUps}>
          <Alert onClose={this.closePopUps} severity='success'>
            Item Message - {itemMessage}
          </Alert>
        </Snackbar>
        <Snackbar open={itemErrorPopup} autoHideDuration={6000} onClose={this.closePopUps}>
          <Alert onClose={this.closePopUps} severity='error'>
            Item Error - {itemError}
          </Alert>
        </Snackbar>

        <Snackbar open={companyMesPopup} autoHideDuration={6000} onClose={this.closePopUps}>
          <Alert onClose={this.closePopUps} severity='success'>
            Company Message - {companyMessage}
          </Alert>
        </Snackbar>
        <Snackbar open={companyErrorPopup} autoHideDuration={6000} onClose={this.closePopUps}>
          <Alert onClose={this.closePopUps} severity='error'>
            Company Error - {companyError}
          </Alert>
        </Snackbar>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  //console.log('this is the alerts state', state);
  return {
    authError: state.auth.authError,
    authMessage: state.auth.authMessage,
    authMessagePopup: state.auth.authMessagePopup,
    authErrorPopup: state.auth.authErrorPopup,
    companyError: state.company.companyError,
    companyMessage: state.company.companyMessage,
    companyErrorPopup: state.company.companyErrorPopup,
    companyMesPopup: state.company.companyMesPopup,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    closeAuthPopUp: () => dispatch(closeAuthPopUp()),
    closeCompanyPopups: () => dispatch(closeCompanyPopups()),
  };
};

export default compose(withStyles(styles), connect(mapStateToProps, mapDispatchToProps), withTranslation())(SystemAlerts);

import geohash from 'ngeohash';
import Geocode from 'react-geocode';
import moment from 'moment';

Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAP_API);

//const fetch = require('node-fetch')
export const addNewCompany = (newCompany) => {
  return (dispatch, getState, { getFirestore, getFirebase }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();
    console.log('this is my new company', newCompany);
    //First check to see if company already doesn't exist

    const address = newCompany.streetAddress + ' ' + newCompany.streetAddress2 + ' ' + newCompany.city + ' ' + newCompany.province + ' ' + newCompany.postalCode + ' ' + newCompany.country;

    Geocode.fromAddress(address)
      .then((response) => {
        const cityLat = response.results[0].geometry.location.lat;
        const cityLong = response.results[0].geometry.location.lng;
        const cityGeohash = geohash.encode(cityLat, cityLong);

        console.log('newCompany from inside addNewCompany', newCompany);

        firestore
          .collection('companies')
          .where('name', '==', newCompany.name)
          .get()
          .then((snapshot) => {
            if (snapshot.empty) {
              return firestore
                .collection('companyCodes')
                .doc('freeCodes')
                .get()
                .then((companyCodeResp) => {
                  const companyCode = companyCodeResp.data().codes[0];

                  const safeCompanyName = newCompany.name.replace(/\s+/g, '-').toLowerCase();
                  //const companyGeohash = geohash.encode(cityLat, cityLng);
                  //console.log('this is my company logo', newCompany.logo);
                  if (newCompany.logo.length !== 0) {
                    firebase
                      .uploadFiles(`companies/${safeCompanyName}/logos`, newCompany.logo)
                      .then((snapshot) => {
                        //Then add the company
                        firestore
                          .collection('companies')
                          .doc()
                          .set({
                            name: newCompany.name,
                            accountNumber: newCompany.accountNumber,
                            companyCode: companyCode,
                            location: {
                              streetAddress: newCompany.streetAddress,
                              streetAddress2: newCompany.streetAddress2,
                              city: newCompany.city,
                              province: newCompany.province,
                              postalCode: newCompany.postalCode,
                              country: newCompany.country,
                              lat: cityLat,
                              long: cityLong,
                              geohash: cityGeohash,
                            },
                            languages: {
                              allLanguages: newCompany.selectedLanguages,
                              preferedLanguage: newCompany.preferedLanguage,
                            },
                            website: newCompany.website,
                            socialMediaAccounts: {
                              facebook: newCompany.facebook,
                              instagram: newCompany.instagram,
                              twitter: newCompany.twitter,
                            },
                            phoneNumber: newCompany.phoneNumber,
                            phoneNumber2: newCompany.phoneNumber2,
                            email: newCompany.email,
                            companyLevel: newCompany.companyLevel,
                            added: new Date(),
                            logo: snapshot[0].uploadTaskSnapshot.metadata.fullPath,
                            owner: newCompany.owner,
                            staff: {},
                            technologies: newCompany.selectedTechnologiesIDs,
                            nationalProgram: newCompany.nationalProgram,
                            nationalProgramID: newCompany.nationalProgramID,
                            nationalProgramName: newCompany.nationalProgramName,
                            shipToAccountNumber: newCompany.shipToAccountNumber,
                          })
                          .then(() => {
                            const unusedcompanyCodes = companyCodeResp.data().codes.filter((code) => code !== companyCode);

                            return firestore
                              .collection('companyCodes')
                              .doc('freeCodes')
                              .set({
                                codes: unusedcompanyCodes,
                              })
                              .then((r) => {
                                return firestore
                                  .collection('companyCodes')
                                  .doc('companyCodesLeft')
                                  .update({
                                    count: firestore.FieldValue.increment(-1),
                                  })
                                  .then((r) => {
                                    return firestore
                                      .collection('companyCodes')
                                      .doc('usedCodes')
                                      .update({
                                        codes: firestore.FieldValue.arrayUnion(companyCode),
                                      })
                                      .then((r) => {
                                        dispatch({
                                          type: 'NEW_COMPANY_CREATED',
                                        });
                                      });
                                  });
                              });
                          })
                          .catch((err) => {
                            dispatch({
                              type: 'ERROR_CREATING_NEW_COMPANY',
                              err,
                            });
                          });
                      })
                      .then(() => {
                        dispatch({ type: 'COMPANY_LOGO_UPDATED' });
                      })
                      .catch((err) => {
                        dispatch({
                          type: 'COMPANY_LOGO_FAILED_TO_UPLOAD',
                          err,
                        });
                      });
                  } else {
                    //Then add the company
                    //console.log('adding company....');
                    firestore
                      .collection('companies')
                      .doc()
                      .set({
                        name: newCompany.name,
                        accountNumber: newCompany.accountNumber,
                        companyCode: companyCode,
                        location: {
                          streetAddress: newCompany.streetAddress,
                          streetAddress2: newCompany.streetAddress2,
                          city: newCompany.city,
                          province: newCompany.province,
                          postalCode: newCompany.postalCode,
                          country: newCompany.country,
                          lat: cityLat,
                          long: cityLong,
                          geohash: cityGeohash,
                        },
                        languages: {
                          allLanguages: newCompany.selectedLanguages,
                          preferedLanguage: newCompany.preferedLanguage,
                        },
                        website: newCompany.website,
                        socialMediaAccounts: {
                          facebook: newCompany.facebook,
                          instagram: newCompany.instagram,
                          twitter: newCompany.twitter,
                        },
                        phoneNumber: newCompany.phoneNumber,
                        phoneNumber2: newCompany.phoneNumber2,
                        email: newCompany.email,
                        companyLevel: newCompany.companyLevel,
                        added: new Date(),
                        logo: '',
                        owner: newCompany.owner,
                        staff: {},
                        technologies: newCompany.selectedTechnologiesIDs,
                        nationalProgram: newCompany.nationalProgram,
                        nationalProgramID: newCompany.nationalProgramID,
                        nationalProgramName: newCompany.nationalProgramName,
                        shipToAccountNumber: newCompany.shipToAccountNumber,
                      })
                      .then(() => {
                        const unusedcompanyCodes = companyCodeResp.data().codes.filter((code) => code !== companyCode);

                        return firestore
                          .collection('companyCodes')
                          .doc('freeCodes')
                          .set({
                            codes: unusedcompanyCodes,
                          })
                          .then((r) => {
                            return firestore
                              .collection('companyCodes')
                              .doc('companyCodesLeft')
                              .update({
                                count: firestore.FieldValue.increment(-1),
                              })
                              .then((r) => {
                                return firestore
                                  .collection('companyCodes')
                                  .doc('usedCodes')
                                  .update({
                                    codes: firestore.FieldValue.arrayUnion(companyCode),
                                  })
                                  .then((r) => {
                                    dispatch({ type: 'NEW_COMPANY_CREATED' });
                                  });
                              });
                          });
                      })
                      .catch((err) => {
                        dispatch({ type: 'ERROR_CREATING_NEW_COMPANY', err });
                      });
                  }
                });
            }
          })
          .catch((err) => {
            dispatch({ type: 'ERROR_SEARCHING_FOR_NEW_COMPANY', err });
            console.log('this was an error in searching for company: ', err);
          });
      })
      .catch((err) => {
        dispatch({ type: 'ERROR_SEARCHING_FOR_NEW_COMPANY_ADDRESS', err });
        console.log('this was an error in searching for companys address: ', err);
      });
  };
};

export const updateCompany = (company) => {
  return (dispatch, getState, { getFirestore, getFirebase }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();
    //console.log('this is my new company', company);
    const address = company.streetAddress + ' ' + company.streetAddress2 + ' ' + company.city + ' ' + company.province + ' ' + company.postalCode + ' ' + company.country;
    //Check to see if they updated the logo
    if (company.logo[0].size) {
      //console.log('I have a file', company.logo[0]);
      const safeCompanyName = company.name.replace(/\s+/g, '-').toLowerCase();

      firebase
        .uploadFiles(`companies/${safeCompanyName}/logos`, company.logo)
        .then((snapshot) => {
          //Then update the company

          Geocode.fromAddress(address).then((response) => {
            const cityLat = response.results[0].geometry.location.lat;
            const cityLong = response.results[0].geometry.location.lng;
            const cityGeohash = geohash.encode(cityLat, cityLong);

            firestore
              .collection('companies')
              .doc(company.id)
              .update({
                name: company.name,
                location: {
                  streetAddress: company.streetAddress,
                  streetAddress2: company.streetAddress2,
                  city: company.city,
                  province: company.province,
                  postalCode: company.postalCode,
                  country: company.country,
                  lat: cityLat,
                  long: cityLong,
                  geohash: cityGeohash,
                },
                languages: {
                  allLanguages: company.selectedLanguages,
                  preferedLanguage: company.preferedLanguage,
                },
                website: company.website,
                socialMediaAccounts: {
                  facebook: company.facebook,
                  instagram: company.instagram,
                  twitter: company.twitter,
                },
                phoneNumber: company.phoneNumber,
                phoneNumber2: company.phoneNumber2,
                email: company.email,
                companyLevel: company.companyLevel,
                lastUpdated: new Date(),
                logo: snapshot[0].uploadTaskSnapshot.metadata.fullPath,
                owner: company.owner,
                staff: {},
                technologies: company.selectedTechnologiesIDs,
                accountNumber: company.accountNumber,
                nationalProgram: company.nationalProgram,
                nationalProgramID: company.nationalProgramID,
                nationalProgramName: company.nationalProgramName,
                shipToAccountNumber: company.shipToAccountNumber,
                terms: company.terms,
                creditLimit: company.creditLimit,
                trainingCompany: company.trainingCompany,
              })
              .then(() => {
                dispatch({ type: 'COMPANY_HAS_BEEN_UPDATED' });
              })
              .catch((err) => {
                dispatch({ type: 'ERROR_UPDATING_COMPANY', err });
              });
          });
        })
        .then(() => {
          dispatch({ type: 'COMPANY_LOGO_UPDATED' });
        })
        .catch((err) => {
          dispatch({ type: 'COMPANY_LOGO_FAILED_TO_UPLOAD', err });
        });
    } else {
      //console.log('I dont have a file', company.logo[0].name);
      //Then update the company
      Geocode.fromAddress(address).then((response) => {
        const cityLat = response.results[0].geometry.location.lat;
        const cityLong = response.results[0].geometry.location.lng;
        const cityGeohash = geohash.encode(cityLat, cityLong);

        firestore
          .collection('companies')
          .doc(company.id)
          .update({
            name: company.name,
            location: {
              streetAddress: company.streetAddress,
              streetAddress2: company.streetAddress2,
              city: company.city,
              province: company.province,
              postalCode: company.postalCode,
              country: company.country,
              lat: cityLat,
              long: cityLong,
              geohash: cityGeohash,
            },
            languages: {
              allLanguages: company.selectedLanguages,
              preferedLanguage: company.preferedLanguage,
            },
            website: company.website,
            socialMediaAccounts: {
              facebook: company.facebook,
              instagram: company.instagram,
              twitter: company.twitter,
            },
            phoneNumber: company.phoneNumber,
            phoneNumber2: company.phoneNumber2,
            email: company.email,
            companyLevel: company.companyLevel,
            lastUpdated: new Date(),
            logo: company.logo[0].name,
            owner: company.owner,
            staff: {},
            technologies: company.selectedTechnologiesIDs,
            accountNumber: company.accountNumber,
            nationalProgram: company.nationalProgram,
            nationalProgramID: company.nationalProgramID,
            nationalProgramName: company.nationalProgramName,
            shipToAccountNumber: company.shipToAccountNumber,
            terms: company.terms,
            creditLimit: company.creditLimit,
            trainingCompany: company.trainingCompany,
          })
          .then(() => {
            dispatch({ type: 'COMPANY_HAS_BEEN_UPDATED' });
          })
          .catch((err) => {
            dispatch({ type: 'ERROR_UPDATING_COMPANY', err });
          });
      });
    }
  };
};

export const getCompanyLogo = (url) => {
  return (dispatch, getState, { getFirestore, getFirebase }) => {
    const firebase = getFirebase();
    //const firestore = getFirestore();
    const storage = firebase.storage().ref();

    storage
      .child(url)
      .getDownloadURL()
      .then((url) => {
        // `url` is the download URL for 'images/stars.jpg'

        // This can be downloaded directly:
        let xhr = new XMLHttpRequest();
        xhr.responseType = 'blob';
        xhr.onload = function (event) {
          //var blob = xhr.response;
        };
        xhr.open('GET', url);
        xhr.send();

        let resp = { url: url };
        dispatch({ type: 'LOGO_WAS_FOUND', resp });
        // Or inserted into an <img> element:
        //let img = document.getElementById('myimg');
        //img.src = url;
      })
      .catch((error) => {
        dispatch({ type: 'LOGO_WAS_NOT_FOUND', error });
      });
  };
};

export const importCompanies = (data) => {
  return (dispatch, getState, { getFirestore, getFirebase }) => {
    //const firebase = getFirebase();
    const firestore = getFirestore();

    console.log('this is my data length before i import it: ', data.length);
    for (let i = 1; i < data.length; i++) {
      let clinicTech = [];

      if (data[i][16]) {
        clinicTech.push('GbtdLM8O9XZhqUI0AdpJ');
      }

      if (data[i][18]) {
        clinicTech.push('iXm85odBrrOKmCXT6kJF');
      }

      if (data[i][19]) {
        clinicTech.push('IsEwUP633cNiauuLEZoT');
      }

      if (data[i][20]) {
        clinicTech.push('eD70aZ5scTNakzNYu5NZ');
      }

      if (data[i][21]) {
        clinicTech.push('tjpVobBxIM3vzGoxZCGa');
      }

      if (data[i][22]) {
        clinicTech.push('Jla4xBu54PEmm1X2FEv5');
      }

      if (data[i][23]) {
        clinicTech.push('y0JgWK5DoLMgcSjdZ18r');
      }

      if (data[i][24]) {
        clinicTech.push('LAWCB5sYq3BmfpF537J0');
      }

      if (data[i][25]) {
        clinicTech.push('yNjpSAzUC6aO5XgVXSfy');
      }

      if (data[i][26]) {
        clinicTech.push('Zr0T4anPDrximulUxZgF');
      }

      if (data[i][27]) {
        clinicTech.push('lhpD9NQXxdL4X0zL2oq0');
      }

      if (data[i][28]) {
        clinicTech.push('CZOdhstKIobP47YKVnrb');
      }

      if (data[i][29]) {
        clinicTech.push('1uKdCvGiEzG8ljPsNj6j');
      }

      if (data[i][30]) {
        clinicTech.push('rLdlouDPcgDU2EHqf9lg');
      }

      if (data[i][31]) {
        clinicTech.push('Khd1Iztfnd0sIlSTdd6T');
      }

      firestore
        .collection('companies')
        .where('name', '==', data[i][0])
        .get()
        .then((snapshot) => {
          if (snapshot.empty) {
            //Add the company
            if (data[i][1]) {
              return firestore
                .collection('companyCodes')
                .doc('freeCodes')
                .get()
                .then((companyCodeResp) => {
                  let companyCode = companyCodeResp.data().codes[i];
                  const companyfileName = data[i][0].replace(/\s+/g, '-').toLowerCase();

                  const companyGeohash = geohash.encode(data[i][34], data[i][35]);
                  firestore
                    .collection('companies')
                    .doc()
                    .set({
                      name: data[i][0],
                      companyCode: companyCode,
                      location: {
                        streetAddress: data[i][10],
                        streetAddress2: data[i][11],
                        city: data[i][12],
                        province: data[i][13],
                        postalCode: data[i][14],
                        country: data[i][15],
                        fullAddress: data[i][33],
                        lat: parseFloat(data[i][34]),
                        long: parseFloat(data[i][35]),
                        geohash: companyGeohash,
                      },
                      languages: {
                        allLanguages: '',
                        preferedLanguage: '',
                      },
                      website: data[i][4],
                      socialMediaAccounts: {
                        facebook: data[i][7],
                        instagram: data[i][9],
                        twitter: data[i][8],
                      },
                      phoneNumber: data[i][6],
                      phoneNumber2: '',
                      email: data[i][5],
                      companyLevel: '',
                      added: new Date(),
                      logo: `companies/${companyfileName}/logos/${data[i][2]}`,
                      owner: '',
                      staff: {},
                      technologies: clinicTech,
                    })
                    .then(() => {
                      const unusedcompanyCodes = companyCodeResp.data().codes.filter((code) => code !== companyCode);

                      return firestore
                        .collection('companyCodes')
                        .doc('freeCodes')
                        .set({
                          codes: unusedcompanyCodes,
                        })
                        .then((r) => {
                          return firestore
                            .collection('companyCodes')
                            .doc('companyCodesLeft')
                            .update({
                              count: firestore.FieldValue.increment(-1),
                            })
                            .then((r) => {
                              return firestore
                                .collection('companyCodes')
                                .doc('usedCodes')
                                .update({
                                  codes: firestore.FieldValue.arrayUnion(companyCode),
                                })
                                .then((r) => {
                                  dispatch({ type: 'NEW_COMPANY_IMPORTED', i });
                                });
                            });
                        });
                    })
                    .catch((err) => {
                      dispatch({ type: 'ERROR_CREATING_NEW_COMPANY', err });
                    });
                });
            }
          }
        });
    }
  };
};

export const getCompanyInfo = (companyID) => {
  return (dispatch, getState, { getFirestore, getFirebase }) => {
    //const firebase = getFirebase();
    const firestore = getFirestore();
    //console.log('this is my new company', newCompany);
    //First check to see if company already doesn't exist
    firestore
      .collection('companies')
      .doc(companyID)
      .get()
      .then((snapshot) => {
        if (snapshot.empty) {
          dispatch({ type: 'NO_COMPANY_WAS_FOUND' });
        } else {
          let clinic = { id: snapshot.id, data: snapshot.data() };
          dispatch({ type: 'COMPANY_FOUND', clinic });
        }
      })
      .catch((err) => {
        dispatch({ type: 'ERROR_SEARCHING_FOR_COMPANY', err });
      });
  };
};

export const getSingleCompanyInfo = (companyID) => {
  return (dispatch, getState, { getFirestore, getFirebase }) => {
    //const firebase = getFirebase();
    const firestore = getFirestore();
    //console.log('this is my new company', newCompany);
    //First check to see if company already doesn't exist
    firestore
      .collection('companies')
      .doc(companyID)
      .get()
      .then((snapshot) => {
        if (snapshot.empty) {
          dispatch({ type: 'NO_COMPANY_WAS_FOUND' });
        } else {
          let clinic = { id: snapshot.id, data: snapshot.data() };
          dispatch({ type: 'SINGLE_COMPANY_FOUND', clinic });
        }
      })
      .catch((err) => {
        dispatch({ type: 'ERROR_SEARCHING_FOR_COMPANY', err });
      });
  };
};

export const updateClinicList = () => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();

    const updateList = firebase.functions().httpsCallable('updateClinicList');

    updateList().then((result) => {
      console.log('list updated? ', result.data);
    });
  };
};

export const addRepToCompany = ({ companyID, repID }) => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();

    dispatch({ type: 'UPDATING_COMPANY' });

    firestore
      .collection('companies')
      .doc(companyID)
      .update({
        rep: repID,
      })
      .then((resp) => {
        dispatch({ type: 'REP_ADDED_TO_COMPANY' });
      })
      .catch((err) => {
        dispatch({ type: 'FAILED_TO_ADD_REP_TO_COMPANY', err });
      });
  };
};

export const closeCompanyPopups = () => {
  return (dispatch, getState, { getFirestore }) => {
    dispatch({ type: 'COMPANY_CLOSE_POPUPS' });
  };
};

export const openCompanyModal = () => {
  return (dispatch, getState, { getFirestore }) => {
    dispatch({ type: 'COMPANY_OPEN_MODAL' });
  };
};

export const closeCompanyModal = () => {
  return (dispatch, getState, { getFirestore }) => {
    dispatch({ type: 'COMPANY_CLOSE_MODAL' });
  };
};

export const companyIsLoaded = () => {
  return (dispatch, getState, { getFirestore }) => {
    dispatch({ type: 'COMPANY_LOADED' });
  };
};

export const sendCompanyRegEmail = ({ email, company, clinicID, regURL }) => {
  return (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();
    //const firebase = getFirebase();
    //console.log('this is my end date', moment(newPromo.promoEndDate).valueOf())

    firestore
      .collection('mail')
      .add({
        cc: email,
        message: {
          subject: `Register with the Solta Advantage Program`,
          text: `Dear ${company.name}, We would like to offer you the chance to register with the Solta Advantage Program. To get started we will need you to register a user for your company with the following link. ${regURL} `,
          html: `Dear ${company.name}, We would like to offer you the chance to register with the Solta Advantage Program. To get started we will need you to register a user for your company with the following link. ${regURL} `,
        },
      })
      .then(() => {
        dispatch({ type: 'COMPANY_REG_EMAIL_SENT' });
      })
      .catch((err) => {
        dispatch({ type: 'ERROR_SENDING_COMPANY_REG_EMAIL', err });
      });
  };
};

export const getCompanyOwner = (userID) => {
  return (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();

    userID.map((u) => {
      return firestore
        .collection('users')
        .doc(u)
        .get()
        .then((snapshot) => {
          if (snapshot.empty) {
            dispatch({ type: 'NO_USER_FOUND' });
          } else {
            let user = { id: snapshot.id, data: snapshot.data() };
            dispatch({ type: 'COMPANY_OWNER_FOUND', user });
          }
        })
        .catch((err) => {
          dispatch({ type: 'ERROR_LOOKING_UP_COMPANY_OWNER', err });
        });
    });
  };
};

export const cleanCompanyCodesCall = () => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();

    const updateList = firebase.functions().httpsCallable('cleanCompanyCodes');

    updateList().then((result) => {
      console.log('list updated? ', result.data);
    });
  };
};

export const removeCompany = (clinicID) => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();
    //const firebase = getFirebase();
    //console.log('this is my end date', moment(newPromo.promoEndDate).valueOf())

    firestore
      .collection('companies')
      .doc(clinicID)
      .delete()
      .then(() => {
        dispatch({ type: 'COMPANY_HAS_BEEN_REMOVED' });
      })
      .catch((err) => {
        dispatch({ type: 'ERROR_REMOVING_COMPANY', err });
      });
  };
};

export const uploadInstallCert = ({ clinicID, cert, user, certDate }) => {
  return (dispatch, getState, { getFirestore, getFirebase }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();
    //const firebase = getFirebase();
    //console.log('this is my end date', moment(newPromo.promoEndDate).valueOf())
    firebase
      .uploadFiles(`certs/${clinicID}`, cert)
      .then((snapshot) => {
        //Then update the company
        let certPath = 'certs.installCert';
        firestore
          .collection('companies')
          .doc(clinicID)
          .update({
            [certPath]: [
              {
                fileName: cert[0].name,
                fileURL: snapshot[0].uploadTaskSnapshot.metadata.fullPath,
                userUploaded: user.fullName,
                userUploadedID: user.id,
                uploadDate: new Date(),
                dateOfCompletion: certDate,
              },
            ],
          })
          .then(() => {
            dispatch({ type: 'COMPANY_CERT_HAS_BEEN_ADDED' });
          })
          .catch((err) => {
            dispatch({ type: 'ERROR_UPDATING_COMPANY', err });
          });
      })
      .catch((err) => {
        dispatch({ type: 'ERROR_UPLOADING_COMPANY_CERT', err });
      });
  };
};

export const uploadAttendanceCert = ({ clinicID, cert, user, certDate }) => {
  return (dispatch, getState, { getFirestore, getFirebase }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();
    //const firebase = getFirebase();
    //console.log('this is my end date', moment(newPromo.promoEndDate).valueOf())
    firebase
      .uploadFiles(`certs/${clinicID}`, cert)
      .then((snapshot) => {
        //Then update the company
        let certPath = 'certs.attendanceCert';
        firestore
          .collection('companies')
          .doc(clinicID)
          .update({
            [certPath]: firestore.FieldValue.arrayUnion({
              fileName: cert[0].name,
              fileURL: snapshot[0].uploadTaskSnapshot.metadata.fullPath,
              userUploaded: user.fullName,
              userUploadedID: user.id,
              uploadDate: new Date(),
              dateOfCompletion: certDate,
            }),
          })
          .then(() => {
            dispatch({ type: 'COMPANY_CERT_HAS_BEEN_ADDED' });
          })
          .catch((err) => {
            dispatch({ type: 'ERROR_UPDATING_COMPANY', err });
          });
      })
      .catch((err) => {
        dispatch({ type: 'ERROR_UPLOADING_COMPANY_CERT', err });
      });
  };
};

export const updateClinicNote = ({ companyID, clinicNote }) => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();

    firestore
      .collection('companies')
      .doc(companyID)
      .update({
        notes: clinicNote,
      })
      .then((resp) => {
        dispatch({ type: 'CLINIC_NOTE_WAS_UPDATED' });
      })
      .catch((err) => {
        dispatch({ type: 'FAILED_TO_UPDATE_CLINIC_NOTE', err });
      });
  };
};

export const updateClinicQuarterGoal = ({ companyID, quarterGoal, year, quarter }) => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();
    console.log('this is my year', year);
    let updatePath = 'goals.' + year + '.' + quarter;
    console.log('this is my updatePath', updatePath);
    firestore
      .collection('companyTotalOrders')
      .doc(companyID)
      .update({
        [updatePath]: quarterGoal,
      })
      .then((resp) => {
        dispatch({ type: 'CLINIC_QUARTER_GOAL_WAS_UPDATED' });
      })
      .catch((err) => {
        dispatch({ type: 'FAILED_TO_UPDATE_CLINIC_QUARTER_GOAL', err });
      });
  };
};

export const updateClinicVPPLevel = ({ companyID, vppLevel }) => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();

    firestore
      .collection('companies')
      .doc(companyID)
      .update({
        companyLevel: vppLevel,
      })
      .then((resp) => {
        dispatch({ type: 'CLINIC_VPP_LEVEL_WAS_UPDATED' });
      })
      .catch((err) => {
        dispatch({ type: 'FAILED_TO_UPDATE_CLINIC_VPP_LEVEL', err });
      });
  };
};

export const sortCompanyList = () => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();
    //const firebase = getFirebase();
    //console.log('this is my end date', moment(newPromo.promoEndDate).valueOf())

    firestore
      .collection('companies')
      .orderBy('name')
      .get()
      .then((snapShot) => {
        if (!snapShot.isEmpty) {
          let companyList = [];
          snapShot.forEach((company) => {
            //console.log('this is my company', company.data());
            companyList.push({ id: company.id, company: company.data().name });
          });

          console.log('this is my companyList', companyList);
          firestore
            .collection('lists')
            .doc('companyList')
            .update({
              clinics: companyList,
            })
            .then(() => {
              firestore.collection('counts').doc('clinicCount').update({
                count: companyList.length,
              });
            });
        } else {
          dispatch({ type: 'NO_COMPANIES_FOUND' });
        }
      })
      .catch((err) => {
        dispatch({ type: 'ERROR_GETTING_COMPANIES', err });
      });
  };
};

export const updateCompanyWarranties = ({ companyID, warranties }) => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();

    firestore
      .collection('companies')
      .doc(companyID)
      .update({
        warranty: [...warranties],
      })
      .then((resp) => {
        dispatch({ type: 'CLINIC_WARRANTIES_WAS_UPDATED' });
      })
      .catch((err) => {
        dispatch({ type: 'FAILED_TO_UPDATE_CLINIC_WARRANTIES', err });
      });
  };
};

export const getWarrantyReport = () => {
  return async (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();
    let midReport = [];

    let saleReps = [];
    let repPull = await firestore.collection('reps').get();

    if (!repPull.empty) {
      await Promise.all(
        repPull.docs.map((rep) => {
          return saleReps.push({ id: rep.id, name: rep.data().fullName });
        })
      );
    }

    firestore
      .collection('companies')
      .orderBy('warranty')
      .get()
      .then((snapShot) => {
        if (!snapShot.isEmpty) {
          snapShot.forEach((company) => {
            //console.log("COMPANY ->", company.data());
            let warrantyDetails = company.data().warranty;
            // console.log(Array.isArray(warrantyDetails));
            //array
            if (Array.isArray(warrantyDetails) === true) {
              warrantyDetails.map((detail) => {
                //console.log("Warr copy line",warrantyDetails[detail]);
                //console.log(detail);
                let rep = [];
                if (company.data().rep) {
                  rep = saleReps.filter((sr) => sr.id === company.data().rep);
                }

                return midReport.push({
                  clinicName: company.data().name,
                  technologyName: detail.technologyName,
                  expireDate: detail.expireDate ? moment(detail.expireDate.toDate()).format('MMMM Do Y') : '-',
                  warrantyLevel: detail.warrantyLevel,
                  serialNumber: detail.serialNumber,
                  rep: rep.length > 0 ? rep[0].name : '-',
                });
              });
            } else {
              let rep = [];
              if (company.data().rep) {
                rep = saleReps.filter((sr) => sr.id === company.data().rep);
              }
              //object
              Object.keys(warrantyDetails).map((l) => {
                //console.log("Object Map L ->",warrantyDetails[l]);
                return firestore
                  .collection('technologies')
                  .doc(l)
                  .get()
                  .then((snapShot) => {
                    if (!snapShot.isEmpty) {
                      return midReport.push({
                        clinicName: company.data().name,
                        technologyName: snapShot.data().name,
                        expireDate: warrantyDetails[l].expireDate ? moment(warrantyDetails[l].expireDate.toDate()).format('MMMM Do Y') : '-',
                        warrantyLevel: warrantyDetails[l].warrantyLevel,
                        serialNumber: warrantyDetails[l].serialNumber,
                        rep: rep.length > 0 ? rep[0].name : '-',
                      });
                    }
                  });
              });
            }
          });
          dispatch({ type: 'REPORT_SUCCESSFUL', midReport });
        }
      });
  };
};

export const uploadClinicPromoAds = ({ promoID, clinicID, promoAd }) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    const firebase = getFirebase();

    //Upload ad1 and then ad 2
    // /promos/ads/clinicID/ad1
    // /promos/ads/clinicID/ad2

    //Once uploaded then add a new entry to the clinic profile
    // promoAds/promoID/ad1:url promoAds/promoID/ad2:url

    firebase.uploadFiles(`promos/ads/${clinicID}/${promoID}/`, promoAd).then((adSnapShot) => {
      let adPath = 'promoAds.' + promoID;

      firestore
        .collection('companies')
        .doc(clinicID)
        .update({
          [adPath]: firestore.FieldValue.arrayUnion(adSnapShot[0].uploadTaskSnapshot.metadata.fullPath),
        })
        .then((resp) => {
          console.log('we send a dispatch saying things worked');
          firestore
            .collection('companies')
            .doc(clinicID)
            .get()
            .then((clinicSnapshot) => {
              let clinic = {
                id: clinicSnapshot.id,
                data: clinicSnapshot.data(),
              };
              dispatch({ type: 'SINGLE_COMPANY_FOUND', clinic });
            });
          dispatch({ type: 'PROMO_ADS_HAVE_BEEN_UPLOADED' });
        });
    });
  };
};
